import React, { useState, useEffect } from 'react';
import { onValue } from 'firebase/database';
import { FoodMenuRef } from '../js/firebase';
import '../css/foodmenu.css';

const categories = [
  { name: "All Items in Our Food-Menu", code: "" },
  { name: "Vegetarian Appetizers", code: "VA" },
  { name: "Main Vegetarian Dishes", code: "MV" },
  { name: "Egg Specialties", code: "ES" },
  { name: "Savory Chicken", code: "SM" },
  { name: "Mutton Selections", code: "MS" },
  { name: "Fish Delicacies", code: "FD" },
  { name: "Rice & Accompaniments", code: "RA" },
  { name: "Chapati (Roti) Varieties", code: "CV" },
  { name: "Thalis (Platter Meals)", code: "TH" },
  { name: "Delicious Desserts", code: "DD" },
  { name: "Refreshing Beverages", code: "RB" },
  { name: "Paratha Specials", code: "PS" },
  { name: "Chinese Momos", code: "CM" },
];

export default function FoodMenuItems() {
  const [packages, setPackages] = useState([]);
  const [visibleItems, setVisibleItems] = useState(null); // Track currently visible category
  const [cart, setCart] = useState([]);
  const [selectedItems, setSelectedItems] = useState({}); // Track selected items with checkboxes
  const [showCart, setShowCart] = useState(false); // Toggle cart visibility
  const [couponCode, setCouponCode] = useState(""); // Store coupon code
  const [discountedTotal, setDiscountedTotal] = useState(null); // Store discounted total if coupon is valid

  useEffect(() => {
    const unsubscribe = onValue(FoodMenuRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const packagesArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setPackages(packagesArray);
      }
    });
    return () => unsubscribe();
  }, []);

  const toggleItems = (code, header) => {
    setVisibleItems(prev => (prev === header ? null : header)); // Toggle logic
  };

  const handleCheckboxChange = (pkgId) => {
    setSelectedItems(prevState => ({
      ...prevState,
      [pkgId]: !prevState[pkgId], // Toggle selection
    }));
  };

  const handleQuantityChange = (pkgId, newQuantity) => {
    setSelectedItems(prevState => ({
      ...prevState,
      [pkgId]: {
        ...prevState[pkgId],
        quantity: newQuantity,
      }
    }));
  };

  const addToCart = (pkg) => {
    if (selectedItems[pkg.id] && selectedItems[pkg.id].quantity > 0) {
      const existingItem = cart.find(item => item.id === pkg.id);
      if (existingItem) {
        setCart(cart.map(item =>
          item.id === pkg.id ? { ...item, quantity: item.quantity + selectedItems[pkg.id].quantity } : item
        ));
      } else {
        setCart([...cart, { ...pkg, quantity: selectedItems[pkg.id].quantity }]);
      }
    }
  };

  const placeOrder = () => {
    // Format the order details to send via WhatsApp
    const orderDetails = cart.map(item => ({
      name: item.itemName,
      quantity: item.quantity,
      price: item.price,
      total: item.quantity * parseFloat(item.price),
    }));

    const orderMessage = `Order Details:\n\n` + orderDetails.map(item =>
      `${item.name} x ${item.quantity} = Rs ${item.total.toLocaleString()}`).join('\n') +
      `\n\nTotal: Rs ${discountedTotal || orderDetails.reduce((total, item) => total + item.total, 0).toLocaleString()}`;

    const phoneNumber = '919907268336'; // WhatsApp number to send the order to
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(orderMessage)}`;

    window.open(url, '_blank'); // Open WhatsApp chat
  };

  const handleCouponApply = () => {
    if (couponCode === "BHOLA50") {
      // Calculate the total price and apply the coupon
      const total = cart.reduce((total, item) => total + item.quantity * parseFloat(item.price), 0);
      const discounted = total / 3; // Apply the discount by dividing the total by 3
      setDiscountedTotal(discounted);
      alert("Coupon Applied! Total has been discounted.");
    } else {
      alert("Invalid Coupon Code.");
      setDiscountedTotal(null); // Reset the discounted total if coupon is invalid
    }
  };

  const totalAmount = discountedTotal || cart.reduce((total, item) => total + item.quantity * parseFloat(item.price), 0);

  return (
    <div className="package-container">
      {!showCart ? (
        <div className="category-buttons">
          {categories.map((category) => (
            <div key={category.code}>
              <button
                className="button-33"
                onClick={() => toggleItems(category.code, category.name)}
              >
                {category.name}
              </button>

              {visibleItems === category.name && (
                <div className="filtered-items">
                  <h2 className="items-header">{category.name}</h2>
                  {packages.filter(pkg => pkg.itemCode.startsWith(category.code)).map((pkg) => (
                    <div className="foodmenu__package-box" key={pkg.id}>
                      <img
                        src={`assets/img/image/foodmenu/${pkg.itemCode}.jpg`}
                        alt={pkg.itemName}
                        className="foodmenu__image"
                      />
                      <div className="foodmenu__info">
                        <div className="foodmenu__item-header">
                          <input
                            type="checkbox"
                            checked={selectedItems[pkg.id] ? selectedItems[pkg.id].selected : false}
                            onChange={() => handleCheckboxChange(pkg.id)}
                          />
                          <span className="select-item-text">Select Item</span> {/* Text next to checkbox */}
                        </div>
                        <span className="foodmenu__name">{pkg.itemName}</span>
                        <span className="foodmenu__code">Item Code: {pkg.itemCode}</span>
                        <span className="foodmenu__description" dangerouslySetInnerHTML={{ __html: pkg.description }} />
                        
                        {/* Show Price Per Plate */}
                        <span className="foodmenu__price">
                          Price: Rs {parseFloat(pkg.price).toLocaleString()} (Per Plate)
                        </span>

                        {/* Show Quantity, Add to Cart button and Go to Cart button */}
                        {selectedItems[pkg.id] && (
                          <div className="foodmenu__actions">
                            <span className="quantity-label">Quantity</span> {/* Text next to quantity input */}
                            <input
                              type="number"
                              placeholder="Quantity"
                              value={selectedItems[pkg.id]?.quantity || 0}
                              min="1"
                              onChange={(e) => handleQuantityChange(pkg.id, Number(e.target.value))}
                            />
                            <button 
                              className="add-to-cart-button"
                              onClick={() => addToCart(pkg)}
                            >
                              Add to Cart
                            </button>

                            {/* Show Go to Cart Button */}
                            {cart.length > 0 && !showCart && (
                              <button 
                                className="go-to-cart-button"
                                onClick={() => setShowCart(true)}
                              >
                                Go to Cart
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        // Cart View
        <div className="cart-container">
          <h2>Your Cart</h2>
          {cart.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <div>
              {cart.map((item) => (
                <div key={item.id} className="cart-item">
                  <span>{item.itemName} x {item.quantity} - Rs {parseFloat(item.price).toLocaleString()} (Per Plate)</span>
                </div>
              ))}
              <div className="cart-total">
                <strong>Total: Rs {totalAmount.toLocaleString()}</strong>
              </div>

              {/* Coupon Code Input */}
              <div className="coupon-section">
                <input
                  type="text"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter Coupon Code"
                  className="coupon-input"
                />
                <button
                  className="apply-coupon-button"
                  onClick={handleCouponApply}
                >
                  Apply Coupon
                </button>
              </div>
            </div>
          )}
          <button 
            className="place-order-button"
            onClick={placeOrder} 
            disabled={cart.length === 0}
          >
            Place Order
          </button>

          {/* Back to Menu Button */}
          <button 
            className="back-to-menu-button"
            onClick={() => setShowCart(false)}
          >
            Back to Menu
          </button>
        </div>
      )}
    </div>
  );
}
